import React from 'react';
import { Formik, Form } from "formik";
import { kycStore } from "../KycContext/KycContext"
import { Row, Col } from "reactstrap";
import { globalStore } from '../../Global/GlobalContext/GlobalContext';
import MyButton from '../../Global/MyButton/MyButton'
import { validateEmail } from '../../../services/kyc.service';
import { catchBody } from '../../Global/Errors/ErrorAlert';
import * as yup from "yup";
import "./EmailVerificationForm.scss";
import FormikInputGroup from '../../Global/FormikInputGroup/FormikInputGroup';


const formValidationSchema = yup.object().shape({
  emailVerificationCode: yup
    .number()
    .transform(value => (isNaN(value) ? 0 : value))
    .min(1, "Introduceți un cod valid (numeric, max 4 caractere)")
    .max(9999, "Introduceți un cod valid (numeric, max 4 caractere)")
    .required("Câmpul este obligatoriu."),
});

export default function EmailVerificationForm() {
  const { kycState, advanceKyc, setKycFieldState, appInsightsTrackEvent } = React.useContext(kycStore);
  const { globalState, isMobile } = React.useContext(globalStore);

  return (
    <Formik
      initialValues={{
        emailVerificationCode: kycState.emailVerificationCode,
      }}
      validationSchema={formValidationSchema}
      onSubmit={async (values) => {

        appInsightsTrackEvent({
          name: "EmailVerificationForm validateEmail",
          properties: {
            emailVerificationCode: kycState.emailVerificationCode,
          }
        });
        validateEmail(kycState.clientSessionId, kycState.contactInfoSessionId, kycState.emailRegistrationId, kycState.emailVerificationCode)
          .then((response) => {



            if (!response.data.success) {
              appInsightsTrackEvent({
                name: "EmailVerificationForm validateEmail - error - invalid code",
              });
              alert("Codul de verificare nu este corect.");
              return;
            }

            appInsightsTrackEvent({
              name: "EmailVerificationForm validateEmail - success",
            });

            advanceKyc();
          })
          .catch((error) => {
            appInsightsTrackEvent({
              name: "EmailVerificationForm validateEmail - error",
              properties: {
                error: error,
              }
            });
            catchBody(error);
          }).finally(() => {
          });
      }}
    >

      <React.Fragment>
        <div className="kyc-form-white">
          <label className="form-title">
            ...și codul primit prin email la adresa {kycState.email}
          </label>
          <Form
            onChange={(e: any) => setKycFieldState(e.target.name, e.target.value)}
          >
            <FormikInputGroup hideLabel={kycState.emailVerificationCode ? false : true} isMobile={isMobile} text="Cod primit prin email" type="tel" name="emailVerificationCode" placeholder="Cod primit prin email"></FormikInputGroup>
            <Row>
              <Col className="d-flex justify-content-end mb-4">
                <Col md={{ size: 5, offset: 7 }} className="d-flex justify-content-end mt-4 mb-2">
                  <MyButton
                    type="submit"
                    small
                    variant="primary"
                    className="mb-1"
                    loading={globalState.loading}
                    disabled={!kycState.contactInfoSessionId}
                  >
                    Verifică
                  </MyButton>
                </Col>
              </Col>
            </Row>
          </Form>
        </div>
      </React.Fragment>
    </Formik>

  );
}
