import React from 'react';
import { Formik, Form, Field, ErrorMessage } from "formik";
import { kycStore } from "../KycContext/KycContext"
import { Row, Col } from "reactstrap";
import MyButton from '../../Global/MyButton/MyButton'
import { registerEmail } from '../../../services/kyc.service';
import { catchBody } from '../../Global/Errors/ErrorAlert';
import * as yup from "yup";
import "./EmailCollectionForm.scss"
import { globalStore } from '../../Global/GlobalContext/GlobalContext';

const formValidationSchema = yup.object().shape({
  email: yup.string().email("Introduceți o adresă de email validă.").required("Câmpul este obligatoriu."),
});


export default function EmailCollectionForm() {
  const { kycState, setKycState, setKycFieldState, advanceKyc, appInsightsTrackEvent } = React.useContext(kycStore);
  const { globalState } = React.useContext(globalStore);


  const createInputGroup = (name: string, label: string, type: string) => {
    return (
      <Col>
        <label className="formLabel">{label}</label>
        <div className="input-group">
          <Field type={type} name={name} className="inputField form-control" />
        </div>
        <ErrorMessage name={name} className="error" component="div" />
      </Col>
    );
  };

  return (
    <Formik
      initialValues={{
        email: kycState.email,
      }}
      validationSchema={formValidationSchema}
      onSubmit={async (values) => {

        appInsightsTrackEvent({
          name: "EmaiCollectionForm - registerEmail",
        });

        registerEmail(kycState.clientSessionId, kycState.contactInfoSessionId, kycState.email)
          .then((response) => {

            appInsightsTrackEvent({
              name: "EmaiCollectionForm - registerEmail - success",
              properties: { emailRegistrationId: response.data.registrationId }
            });

            setKycState({
              emailRegistrationId: response.data.registrationId,
            });
            advanceKyc();
          })
          .catch((error) => {
            
            appInsightsTrackEvent({
              name: "EmaiCollectionForm - registerEmail - error",
              properties: { error: error }
            });
            catchBody(error);
          })
          .finally(() => {
          });

      }}
    >

      <React.Fragment>
        <header>
          <p>
            Email collection form header
            </p>
        </header>
        <Form
          onChange={(e: any) => setKycFieldState(e.target.name, e.target.value)}
        >
          <Row className="mt-2">
            {createInputGroup("email", "Email", "email")}
          </Row>
          <Row>
            <Col className="d-flex justify-content-end mb-4">
              <Col md={{ size: 5, offset: 7 }} className="d-flex justify-content-end mt-4 mb-2">
                <MyButton
                  type="submit"
                  small
                  variant="primary"
                  className="mb-1"
                  loading={globalState.loading}
                  disabled={!kycState.contactInfoSessionId}
                >
                  Continuă
                  </MyButton>
              </Col>
            </Col>
          </Row>

        </Form>
      </React.Fragment>
    </Formik>
  );
}
