import React from 'react';
import { Route, Switch, BrowserRouter } from 'react-router-dom';
import './App.scss';
import { KycContext } from './components/Kyc/KycContext/KycContext';
import KycHome from './components/Kyc/KycHome/KycHome';
import KycFilboMobileRedirect from './components/Kyc/KycFilboMobileRedirect/KycFilboMobileRedirect';
import { GlobalContext } from './components/Global/GlobalContext/GlobalContext';

function App() {
  return (
    <GlobalContext>
      <BrowserRouter>
        <Switch>
          <KycContext>
            <Route path="/" component={KycHome} exact>
            </Route>
            <Route path="/filbo-mobile-redirect" component={KycFilboMobileRedirect} exact>
            </Route>
            {/* <Route path="/kyc" component={KycHome} exact /> */}
          </KycContext>
        </Switch>
      </BrowserRouter>
    </GlobalContext>
  );
}

export default App;
