
import axios from "axios";

export const axiosKycServiceClient = axios.create({
  baseURL: process.env.REACT_APP_KYC_URL,
  headers: {
    Accept: "application/json",
  },
});

export const getSessionId = async (clientSessionId: string) => {
  return axiosKycServiceClient.post("/contact/v1.0/session", { clientSessionId: clientSessionId });
};

export const createRapidKycSession = (request: any) => {
  return axiosKycServiceClient.post("/kyc/v1.0/session/rapid", request);
};

export const getRapidKycSession = async (providerSessionId: string, language: string) => {
  let url = "/kyc/v1.0/session/rapid?providerSessionId=" + providerSessionId + "&language=" + language;
  return axiosKycServiceClient.get(url);
};

export const registerPhone = (clientSessionId: string, contactInfoSessionId: string, phoneNumber: string) => {
  var request = {
    clientSessionId: clientSessionId,
    sessionId: contactInfoSessionId,
    phoneNumber: phoneNumber,
  };
  return axiosKycServiceClient.post("/contact/v1.0/phone/register", request);
};

export const registerEmail = (clientSessionId: string, contactInfoSessionId: string, email: string) => {
  var request = {
    clientSessionId: clientSessionId,
    sessionId: contactInfoSessionId,
    emailAddress: email.toLowerCase(),
  };
  return axiosKycServiceClient.post("/contact/v1.0/email/register", request);
};


export const validatePhone = (clientSessionId: string, sessionId: string, phoneRegistrationId: string, code: string): Promise<any> => {

  var request = {
    clientSessionId: clientSessionId,
    sessionId: sessionId,
    registrationId: phoneRegistrationId,
    localId: null,
    code: code,
  }
  return axiosKycServiceClient.post("/contact/v1.0/phone/validate", request);
};

export const validateEmail = (clientSessionId: string, contactInfoSessionId: string, emailRegistrationId: string, code: string): Promise<any> => {

  var request = {
    clientSessionId: clientSessionId,
    sessionId: contactInfoSessionId,
    registrationId: emailRegistrationId,
    localId: null,
    code: code,
  }
  return axiosKycServiceClient.post("/contact/v1.0/email/validate", request);
};

export const createEmailConsents = (request: any): Promise<any> => {
  return axiosKycServiceClient.post("/consent/v1.0/email", request);
};