import React from 'react';
import Text from '../../Global/Text/Text'
import "./ThankYou.scss"

export default function ThankYou() {
  return (
    <React.Fragment>
      <label className="form-title">
        Mulțumim!
      </label>

      <img src="/assets/svg/thumbs_up.svg" alt="like" className="like" />

      <Text tag="p" size={18} className="liketext mt-3" align="center" color="#9e9e9e">
        Am primit datele tale și le vom valida cât mai repede.<br />
        Un consilier din echipa Filbo te va contacta pentru a îți explica pașii următori.
      </Text>
    </React.Fragment >
  );
};