import { Col, Row, Progress } from "reactstrap";
import React from "react";
import Text from "../../Global/Text/Text";
import "./ProgressBarLine.scss";
import classNames from "classnames";

interface Props {
    percent: number;
    className?: string;
}

export default function ProgressBarLine({
    percent,
    className,
    ...props
}: Props) {
    const finalClass = classNames("progress-bar-container", className);
    return (
        <div className={finalClass} {...props} >
            <Row>
                <Col className={"col-1"}>
                    <Text align={"center"} size={10} style={{ color: "#c7c7c7" }}>
                        {`${percent}%`}
                    </Text>
                </Col>
                <Col className={"col-10"}>
                    <Progress className="progress-bar-line"
                        value={percent}
                        text={`${percent}%`}
                        strokeWidth={3}
                    />
                </Col>
            </Row>
        </div>
    );
}