import React from "react";
import classNames from "classnames";
interface Props {
  size?: number;
  color?: string;
  weight?: number;
  className?: string;
  align: "center" | "left" | "right" | "justify";
  children: React.ReactNode;
  style?: any;
  tag: "span" | "small" | "p" | "strong" | "em" | "h1" | "h2" | "h3" | "h4" | "h5";
  refProp?: any;
}

export default function Text({
  size,
  color,
  weight,
  className,
  align,
  children,
  tag,
  style,
  refProp,
  ...props
}: Props) {
  const finalStyle = classNames("Text", className);
  return React.createElement(
    tag,
    {
      className: finalStyle,
      style: {
        fontWeight: weight,
        color: color,
        fontSize: `${size}px`,
        textAlign: align,
        ...style,
      },
      ...props,
      ref: refProp,
    },
    children
  );
}
Text.defaultProps = {
  size: 16,
  color: "#1B3F74",
  weight: 400,
  tag: "span",
  align: "left",
};
