export function isString(value: any) {
    return typeof value === 'string' || value instanceof String;
}

export function isEmptyOrNullOrWhitespace(str: string) {
    if (!str || 0 === str.length) return true;

    if (!!str.trim()) return true;

    return false;


}