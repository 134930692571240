import React, { Ref } from "react";
import "./Header.scss";
import classNames from "classnames";

interface Props {
  children: React.ReactNode;
  className?: string;
  refProp?: Ref<any>;
}

export default function Header({
  children,
  className,
  refProp,
  ...props
}: Props) {
  const finalClass = classNames("header", className);
  return (
    <div className={finalClass} {...props} ref={refProp}>
      <label className="header-text mb-0">
        {children}
      </label>
      <img className="header-logo" alt=" " src="/assets/svg/filbo_logo_black.svg" />
    </div>
  );
}
