import React from 'react';
import { kycStore } from "../KycContext/KycContext"
import { Row, Col, Card, CardBody } from "reactstrap";
import { globalStore } from '../../Global/GlobalContext/GlobalContext';
import MyButton from '../../Global/MyButton/MyButton'
import { createRapidKycSession } from '../../../services/kyc.service';
import { catchBody } from '../../Global/Errors/ErrorAlert';
import "./ExternalKycProviderConsentForm.scss";
import { isEmptyOrNullOrWhitespace } from "./../../Global/Utils/StringUtils"
import { KycForm } from '../KycContext/kycAvailableSteps';

export default function ExternalKycProviderConsentForm() {
  const { kycState, setKycState, advanceKyc, appInsightsTrackEvent } = React.useContext(kycStore);
  const { isMobile, globalState } = React.useContext(globalStore);

  const handleContinueButtonClick = async (event: any) => {

    if (kycState.kycProviderUrls) {
      advanceKyc();
    }
    else {
      const request = {
        Language: "RO",
        contactInfoClientSessionId: kycState.clientSessionId,
        contactInfoSessionId: kycState.contactInfoSessionId,
        Phone: null,
        Email: null
      };
  
      if (!isEmptyOrNullOrWhitespace(kycState.phoneNumber)) {
        request.Phone = kycState.phoneNumber;
      }
  
      if (!isEmptyOrNullOrWhitespace(kycState.email)) {
        request.Email = kycState.email.toLowerCase();
      }
  
      appInsightsTrackEvent({
        name: "ExternalKycProviderConsentForm createRapidKycSession",
      });

      createRapidKycSession(request)
      .then((response) => {

        appInsightsTrackEvent({
          name: "ExternalKycProviderConsentForm createRapidKycSession - success"
        });

        var urlsDict: { [id: string]: string; } = {};

        if (response.data.selfieUrl !== null) {
          urlsDict[KycForm.ExternalKycProviderSelfie] = response.data.selfieUrl;
        }
        if (response.data.livenessUrl !== null) {
          urlsDict[KycForm.ExternalKycProviderLiveness] = response.data.livenessUrl;
        }
        if (response.data.ocrUrl !== null) {
          urlsDict[KycForm.ExternalKycProviderIdScan] = response.data.ocrUrl;
        }

        setKycState({
          localId: response.data.localId,
          kycProviderUrls: urlsDict,
        });

        advanceKyc();

      })
      .catch((error) => {
        appInsightsTrackEvent({
          name: "ExternalKycProviderConsentForm createRapidKycSession - error",
          properties: {
            error: error,
          }
        });
        catchBody(error);
      })
      .finally(() => {
      });
    }  
  };


  return (
    <React.Fragment>
      <div className="kyc-form-white">

        <Row>
          <Col md={12} className="p-0">
            <Card className="textLeft consentCard">
              <CardBody>
                <Row>
                  <Col md={4}>
                    <img src="/assets/svg/selfie.svg" alt="selfie" style={isMobile ? { maxWidth: "30%", height: "auto", marginLeft: "35%" } : { maxWidth: "100%", height: "auto" }} />
                  </Col>
                  <Col md={8}>
                    Ce pași vei urma:
                          <br></br>
                    <br></br>
                    <ul style={{ listStyleType: "none" }}>
                      <li className="mb-4">
                        <Row className="mt-2" noGutters style={{ lineHeight: "20px" }}>
                          <Col style={{ maxWidth: "14%" }}>
                            <img src="/assets/svg/1.svg" alt="1" style={{ height: "24px", width: "24px" }} />
                          </Col>
                          <Col style={{ marginTop: "2.5px" }}>
                            <span className="textLeft text">
                              Permite accesul la cameră.
                                  </span>
                          </Col>
                        </Row>
                      </li>
                      <li className="mb-4">
                        <Row className="mt-2" noGutters style={{ lineHeight: "20px" }}>
                          <Col style={{ maxWidth: "14%" }}>
                            <img src="/assets/svg/2.svg" alt="2" style={{ height: "24px", width: "24px" }} />
                          </Col>
                          <Col style={{ marginTop: "2.5px" }}>
                            <span className="textLeft text">
                              Fă un selfie.
                            </span>
                          </Col>
                        </Row>
                      </li>
                      <li className="mb-4">
                        <Row className="mt-2" noGutters style={{ lineHeight: "20px" }}>
                          <Col style={{ maxWidth: "14%" }}>
                            <img src="/assets/svg/3.svg" alt="3" style={{ height: "24px", width: "24px" }} />
                          </Col>
                          <Col style={{ marginTop: "2.5px" }}>
                            <span className="textLeft text">
                              Efectuează o validare video de doar câteva secunde urmând instrucțiunile de pe ecran.
                            </span>
                          </Col>
                        </Row>
                      </li>
                      <li>
                        <Row className="mt-2" noGutters style={{ lineHeight: "20px" }}>
                          <Col style={{ maxWidth: "14%" }}>
                            <img src="/assets/svg/4.svg" alt="4" style={{ height: "24px", width: "24px" }} />
                          </Col>
                          <Col style={{ marginTop: "2.5px" }}>
                            <span className="textLeft text">
                              Fă o poză cărții tale de identitate.
                            </span>
                          </Col>
                        </Row>
                      </li>
                    </ul>
                  </Col>
                </Row>
              </CardBody>
            </Card>
          </Col>
        </Row>
        <Row>
          {/* <Col className="d-flex justify-content-end mb-4"> */}
          <Col md={{ size: 5, offset: 7 }} className="d-flex justify-content-end mt-4 mb-2">
            <MyButton small onClick={handleContinueButtonClick}
              loading={globalState.loading}
              disabled={!kycState.contactInfoSessionId}
              style={{ width: "100%" }}>
              Continuă
              </MyButton>
          </Col>
          {/* </Col> */}
        </Row>

      </div>
    </React.Fragment>
  );
}
