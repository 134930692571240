import { createContext, useReducer, useEffect, useCallback } from "react";
import React from 'react';
import { catchBody } from "../../Global/Errors/ErrorAlert";
import { getSessionId, axiosKycServiceClient } from "../../../services/kyc.service";
import { globalStore } from '../../Global/GlobalContext/GlobalContext';
import { kycAvailableSteps, KycStep, KycForm } from "./kycAvailableSteps";
import { ApplicationInsights } from "@microsoft/applicationinsights-web";


const defaultStepsConfiguration = [KycStep.PhoneAndEmail, KycStep.ExternalKycProvider, KycStep.ThankYou];
//const defaultStepsConfiguration = [KycStep.PhoneAndEmail, KycStep.ThankYou];

const initialState: { [key: string]: any } = {
    currentForm: KycForm.PhoneAndEmailCollection,
    email: '',
    phoneNumber: '',
    contactInfoSessionId: "",
    clientSessionId: "",
    stepsConfiguration: defaultStepsConfiguration,
    currentStepIndex: -1,
    currentInternalStepIndex: -1,
    phoneRegistrationId: "",
    phoneNumberVerificationCode: "",
    emailRegistrationId: "",
    emailVerificationCode: "",
    percent: 0,
    currentAbsoluteStep: 0,
    termsAndConditions: false,
    agreement: false,
    creditBureau: false
};


export const kycStore = createContext(initialState);

interface Props {
    children: React.ReactNode;
}

let appInsightsClient: ApplicationInsights;

const { Provider } = kycStore;

function generateUUID() {
    var d = new Date().getTime(); //Timestamp
    var d2 = (performance && performance.now && performance.now() * 1000) || 0;
    return "xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx".replace(/[xy]/g, function (c) {
        var r = Math.random() * 16;
        if (d > 0) {
            r = (d + r) % 16 | 0;
            d = Math.floor(d / 16);
        } else {
            r = (d2 + r) % 16 | 0;
            d2 = Math.floor(d2 / 16);
        }
        return (c === "x" ? r : (r & 0x3) | 0x8).toString(16);
    });
}
export const KycContext = ({ children }: Props) => {
    const [kycState, setKycState]: [any, any] = useReducer<any>((s: any, a: any) => {
        return { ...s, ...a };
    }, initialState);

    const { setGlobalState } = React.useContext(globalStore);

    useEffect(() => {
        axiosKycServiceClient.interceptors.request.use(
            (request: any) => {
                setGlobalState({ loading: true });
                console.log(new Date().toISOString() + ` requestLogger. Url: ${request.url} Method: ${request.method}. Request: ${JSON.stringify(request.data)}`);
                return request;
            }
        );

        axiosKycServiceClient.interceptors.response.use(
            (response: any) => {
                setGlobalState({ loading: false });
                console.log(new Date().toISOString() + ` responseLogger. status: ${response.status} Data: ${JSON.stringify(response.data)}`);
                return response;
            },
            (error: any) => {
                setGlobalState({ loading: false });
                console.log(new Date().toISOString() + ` responseLogger.  Error: ${JSON.stringify(error)}`);
                return Promise.reject(error);
            }
        );
    }, [setGlobalState]);

    const advanceKyc = useCallback(() => {

        appInsightsTrackEvent({
            name: "advanceKyc",
        });

        const moveToNextKycStep = () => {
            if (kycState.currentStepIndex === kycState.stepsConfiguration.length - 1) {
                console.log(new Date().toISOString() + `moveToNextKycStep. We are at the final kyc step ${kycState.currentStepIndex}`);
                //do nothing, we have finalized the process and are showing the thank you page (if all is configured correctly)
            }
            else {
                console.log(new Date().toISOString() + ` moveToNextKycStep from  ${kycState.currentStepIndex} to ${kycState.currentStepIndex + 1}`);
                setKycState({
                    currentStepIndex: kycState.currentStepIndex + 1,
                    currentInternalStepIndex: 0,
                    currentAbsoluteStep: kycState.currentAbsoluteStep + 1
                });
            }
        }

        const currentStep = kycAvailableSteps.filter((s: any) => s.kycStep === kycState.stepsConfiguration[kycState.currentStepIndex])[0];

        if (kycState.currentInternalStepIndex === currentStep.formsConfiguration.length - 1) {
            console.log(new Date().toISOString() + `advanceKyc. We are at the final form of this kyc step ${kycState.currentStepIndex}`);
            moveToNextKycStep();
        }
        else {
            console.log(new Date().toISOString() + ` advanceKyc internal step from  ${kycState.currentInternalStepIndex} to ${kycState.currentInternalStepIndex + 1}`);
            setKycState({
                currentInternalStepIndex: kycState.currentInternalStepIndex + 1,
                currentAbsoluteStep: kycState.currentAbsoluteStep + 1
            });
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [kycState.currentInternalStepIndex, kycState.currentStepIndex, kycState.stepsConfiguration, kycState.currentAbsoluteStep]);

    const setKycFieldState = useCallback((field: string, value: any) => {
        if (typeof (kycState[field]) === "boolean") {
            setKycState({
                [field]: !kycState[field],
            });
        }
        else {
            if (typeof value === 'string') {
                value = value.trim();
            }
            setKycState({
                [field]: value,
            });
        }
    }, [kycState, setKycState]);

    const startNewKycClientSession = useCallback((steps?: []) => {
        const newClientSessionId = generateUUID();
        console.log(new Date().toISOString() + ` startNewKycClientSession. newClientSessionId = ${newClientSessionId}`);

        appInsightsTrackEvent({
            name: "startNewKycClientSession get sessionId",
            properties: {
                clientSessionId: newClientSessionId,
            }
        });


        setKycState({
            clientSessionId: newClientSessionId
        });

        getSessionId(newClientSessionId)
            .then((response) => {
                console.log(new Date().toISOString() + " contact info session get - success: " + response.data.sessionId);
                appInsightsTrackEvent({
                    name: "startNewKycClientSession get sessionId - success",
                    properties: {
                        sessionId: response.data.sessionId,
                    }
                });
                if (steps) {
                    setKycState({
                        stepsConfiguration: steps,
                    });
                }
                else {
                    setKycState({
                        stepsConfiguration: defaultStepsConfiguration,
                    });
                }
                setKycState({
                    contactInfoSessionId: response.data.sessionId,
                    currentStepIndex: 0,
                    currentInternalStepIndex: 0,
                    currentAbsoluteStep: 0,
                });

            })
            .catch((error) => {
                console.log(new Date().toISOString() + " session get - error: " + JSON.stringify(error));
                appInsightsTrackEvent({
                    name: "startNewKycClientSession get sessionId - error",
                    properties: {
                        error: error,
                    }
                });
                catchBody(error);
            })
            .finally(() => {
            });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setKycState]);

    const continueKycClientSession = useCallback((clientSessionId: string, contactInfoSessionId: string, steps?: KycStep[]) => {

        console.log(new Date().toISOString() + ` continueKycClientSession. clientSessionId = ${clientSessionId}, contactInfoSessionId = ${contactInfoSessionId}`);

        appInsightsTrackEvent({
            name: "continueKycClientSession Starting",
            properties: {
                clientSessionId: clientSessionId,
                contactInfoSessionId: contactInfoSessionId
            }
        });

        setKycState({
            clientSessionId: clientSessionId,
            contactInfoSessionId: contactInfoSessionId
        });

        if (steps && steps.length > 0) {

            console.log(new Date().toISOString() + ` requested predefined steps:`);
            console.log(steps);

            appInsightsTrackEvent({
                name: "continueKycClientSession requested predefined steps",
                properties: {
                    steps: steps,
                }
            });


            setKycState({
                stepsConfiguration: steps,
            });
        }
        else {
            steps = defaultStepsConfiguration;
            console.log(new Date().toISOString() + ` no predefined steps. Going with default configuration:`);
            console.log(steps);

            appInsightsTrackEvent({
                name: "continueKycClientSession no predefined steps",
                properties: {
                    steps: steps,
                }
            });

            setKycState({
                stepsConfiguration: steps,
            });
        }
        setKycState({
            currentStepIndex: 0,
            currentInternalStepIndex: 0,
            currentAbsoluteStep: 0,
        });
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [setKycState]);

    useEffect(() => {
        if (kycState.currentInternalStepIndex >= 0) {

            const currentStep = kycAvailableSteps.filter((s: any) => s.kycStep === kycState.stepsConfiguration[kycState.currentStepIndex])[0];

            let newForm = currentStep.formsConfiguration[kycState.currentInternalStepIndex];
            let newCurrentInternalStepIndex = kycState.currentInternalStepIndex;
            let shouldUpdateCurrentInternalStepIndex = false;

            if (currentStep.kycStep === KycStep.ExternalKycProvider && kycState.currentInternalStepIndex > 0) {
                let tempForm = currentStep.formsConfiguration[kycState.currentInternalStepIndex];
                let tempCurrentInternalStepIndex = kycState.currentInternalStepIndex;
                while (kycState.kycProviderUrls[tempForm] === undefined) {
                    tempForm = currentStep.formsConfiguration[++tempCurrentInternalStepIndex];
                }
                newForm = tempForm;
                newCurrentInternalStepIndex = tempCurrentInternalStepIndex;
                shouldUpdateCurrentInternalStepIndex = kycState.currentInternalStepIndex !== tempCurrentInternalStepIndex ? true : false;
            }

            console.log(new Date().toISOString() + " currentInternalStepIndex newForm " + newForm);

            const totalSteps = kycState.stepsConfiguration.reduce((acc: number, element: any) => {
                const elem = kycAvailableSteps.filter((s: any) => s.kycStep === element)[0];

                if (elem.kycStep === KycStep.ExternalKycProvider && kycState.kycProviderUrls) {
                    let externalKycProviderStepLength = 1;
                    elem.formsConfiguration.forEach((kycForm) => {
                        if (kycState.kycProviderUrls[kycForm] !== undefined) {
                            externalKycProviderStepLength++;
                        }
                    });
                    return acc + externalKycProviderStepLength;
                }

                return acc + elem.formsConfiguration.length;
            }, 0);
            const newPercent = Math.round((kycState.currentAbsoluteStep) / (totalSteps - 1) * 100);

            if (shouldUpdateCurrentInternalStepIndex) {
                setKycState({
                    currentForm: newForm,
                    percent: newPercent,
                    currentInternalStepIndex: newCurrentInternalStepIndex,
                });
            }
            else {
                setKycState({
                    currentForm: newForm,
                    percent: newPercent,
                });
            }
        }

    }, [kycState.currentInternalStepIndex, kycState.currentStepIndex, kycState.stepsConfiguration, kycState.currentAbsoluteStep, kycState.kycProviderUrls]);

    ///Initialize app insights
    useEffect(() => {
        if (process.env.REACT_APP_APP_INSIGHTS_INSTRUMENTATION_KEY) {
            appInsightsClient = new ApplicationInsights({
                config: {
                    instrumentationKey: process.env.REACT_APP_APP_INSIGHTS_INSTRUMENTATION_KEY,
                },
            });
            appInsightsClient.loadAppInsights();
        }
    }, [])

    const appInsightsTrackEvent = useCallback((eventData: any) => {
        if (appInsightsClient) {
            eventData.properties = {
                ...eventData.properties, ...{
                    email: kycState.email.toLowerCase(),
                    phoneNumber: kycState.phoneNumber.toLowerCase(),
                    contactInfoSessionId: kycState.contactInfoSessionId,
                    clientSessionId: kycState.clientSessionId,
                    currentStepIndex: kycState.currentStepIndex,
                    currentInternalStepIndex: kycState.currentInternalStepIndex,
                    phoneRegistrationId: kycState.phoneRegistrationId,
                    emailRegistrationId: kycState.emailRegistrationId,
                }
            }
            appInsightsClient.trackEvent(eventData);
        }
    }, [kycState]);

    return (
        <Provider value={{
            kycState,
            setKycState,
            advanceKyc,
            setKycFieldState,
            startNewKycClientSession,
            continueKycClientSession,
            appInsightsTrackEvent,
        }} >
            {children}
        </Provider >
    );
}
