import { createContext, useReducer } from "react";
import React from 'react';
import { useMediaQuery } from "react-responsive";

const initialState: { [key: string]: any } = {
  loading: false,
};

export const globalStore = createContext(initialState);

interface Props {
  children: React.ReactNode;
}

const { Provider } = globalStore;


export const GlobalContext = ({ children }: Props) => {
  const [globalState, setGlobalState]: [any, any] = useReducer<any>((s: any, a: any) => {
    return { ...s, ...a };
  }, initialState);

  const isMobile = useMediaQuery({
    query: "(max-device-width: 991px)",
  });


  return (
    <Provider value={{
      globalState,
      setGlobalState,
      isMobile
    }} >
      {children}
    </Provider >
  );
}
