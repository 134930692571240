import React from "react";
import "./FormikInputGroup.scss";
import classNames from "classnames";
import { Field, ErrorMessage } from "formik";


interface Props {
  text: string;
  type: string;
  name: string;
  placeholder: string;
  isMobile: boolean;
  hideLabel: boolean;
  className?: string;
}

export default function FormikInputGroup({
  text,
  type,
  name,
  placeholder,
  isMobile,
  hideLabel,
  className,
}: Props) {
  const inputClasses = classNames(
    className,
    "inputField",
    "form-control",
    {
      "desktopInput": !isMobile,
    },
    
  );
  return (
    <React.Fragment >
      <label className={classNames("formLabel","mb-0", { "hidden": hideLabel })}>{text}</label>
      <Field type={type} name={name} className={inputClasses} placeholder={placeholder} />
      <ErrorMessage name={name} className="error" component="div" />
    </React.Fragment>
  );
}
