import React from 'react';
import { kycStore } from "../KycContext/KycContext"
import Text from '../../Global/Text/Text'
import "./FilboMobileRedirectHelper.scss"

export default function FilboMobileRedirectHelper() {
  const { kycState } = React.useContext(kycStore);

  return (
    <React.Fragment>
      <label className="form-title">
        Mulțumim!
      </label>

      <img src="/assets/svg/thumbs_up.svg" alt="like" className="like" />

      <Text tag="p" size={18} className="liketext mt-3" align="center" color="#9e9e9e">
        Asta a fost tot! Datele tale au fost verificate cu succes<br />
        Apasă <a id="chrome-anchor" href={kycState.filboMobileRedirectUrl}>aici</a> pentru a fi redirecționat(ă) către aplicația mobilă.
      </Text>
    </React.Fragment >
  );
};