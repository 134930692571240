import React from 'react';
import { kycStore } from "../KycContext/KycContext"
import { globalStore } from '../../Global/GlobalContext/GlobalContext';
import "./ExternalKycProviderIFrameForm.scss";
import Iframe from "react-iframe";

export default function ExternalKycProviderIFrameForm() {
  const { kycState, advanceKyc, appInsightsTrackEvent } = React.useContext(kycStore);
  const { isMobile } = React.useContext(globalStore);

  document?.getElementById('iframePlaceToScroll')?.scrollIntoView();

  React.useEffect(() => {
    listenToAriadnextEvents();
    listenToStarbyteEvents();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [kycState]);

  const isAriadnextEvent = (event: any) => {
    if (event.data?.idcheckio) {
      return true;
    }
    return false;
  };

  function listenToAriadnextEvents() {

    const listener = (event: any) => {
      console.log(new Date().toISOString() + " event received from iframe " + JSON.stringify(event));
      console.log(event);

      if (!process.env.REACT_APP_KYC_CALLBACK_ORIGIN_HOST_LIST?.split(";").includes(event.origin)) {
        console.log(new Date().toISOString() + " event from other origin received. " + JSON.stringify(event) + JSON.stringify(event.origin));
        appInsightsTrackEvent({
          name: "ExternalKycProviderIFrameForm event from other origin received",
          properties: {
            event: event,
            eventOrigin: event.origin,
            allowedOrigin: process.env.REACT_APP_KYC_CALLBACK_ORIGIN_HOST_LIST,
          }
        });
        return;
      }

      if (!isAriadnextEvent(event)) {
        return;
      }

      appInsightsTrackEvent({
        name: "ExternalKycProviderIFrameForm event received from iframe",
        properties: {
          event: event,
          eventData: event.data,
          eventOrigin: event.origin,
        }
      });

      console.log(new Date().toISOString() + " iframe currentInternalStepIndex " + JSON.stringify(kycState.currentInternalStepIndex));

      if (event.data?.idcheckio?.event !== "ENDED") {
        console.log(new Date().toISOString() + " event from other origin received. " + JSON.stringify(event) + JSON.stringify(event.origin));
        appInsightsTrackEvent({
          name: "ExternalKycProviderIFrameForm event received from iframe - data.idcheckio.event not ENDED. returning",
          properties: {
            event: event,
            eventData: event.data,
          }
        });
        console.log(new Date().toISOString() + " data.idcheckio.event not ENDED " + JSON.stringify(event));
        return;
      }

      advanceKyc();
    };

    console.log(new Date().toISOString() + " adding event listener");
    window.addEventListener("message", listener);

    return () => {
      console.log(new Date().toISOString() + " removing event listener");
      window.removeEventListener("message", listener);
    };
  }

  function listenToStarbyteEvents() {

    const listener = (event: any) => {
      console.log(new Date().toISOString() + " event received from iframe " + JSON.stringify(event));
      console.log(event);

      if (isAriadnextEvent(event)) {
        return;
      }

      appInsightsTrackEvent({
        name: "ExternalKycProviderIFrameForm event received from iframe",
        properties: {
          event: event,
          eventData: event.data,
          eventOrigin: event.origin,
        }
      });

      console.log(new Date().toISOString() + " iframe currentInternalStepIndex " + JSON.stringify(kycState.currentInternalStepIndex));

      if (!process.env.REACT_APP_KYC_CALLBACK_ORIGIN_HOST_LIST?.split(";").includes(event.origin)) {
        console.log(new Date().toISOString() + " event from other origin received. " + JSON.stringify(event) + JSON.stringify(event.origin));
        appInsightsTrackEvent({
          name: "ExternalKycProviderIFrameForm event from other origin received",
          properties: {
            event: event,
            eventOrigin: event.origin,
            allowedOrigin: process.env.REACT_APP_KYC_CALLBACK_ORIGIN_HOST_LIST,
          }
        });
        return;
      }

      if (event.data.info !== "OK") {
        appInsightsTrackEvent({
          name: "ExternalKycProviderIFrameForm event received from iframe - data.info not ok. returning",
          properties: {
            event: event,
            eventData: event.data,
          }
        });
        console.log(new Date().toISOString() + " data.info is not OK " + JSON.stringify(event));
        return;
      }

      advanceKyc();
    };

    console.log(new Date().toISOString() + " adding event listener");
    window.addEventListener("message", listener);

    return () => {
      console.log(new Date().toISOString() + " removing event listener");
      window.removeEventListener("message", listener);
    };

  }
  return (
    <React.Fragment>
      <div className="kyc-form-white">
        <Iframe id="iframePlaceToScroll" key={kycState.kycProviderUrls[kycState.currentForm]} src={kycState.kycProviderUrls[kycState.currentForm]} frameBorder={0} url={kycState.kycProviderUrls[kycState.currentForm]} className={isMobile ? "iframe_mobile" : "iframe_desktop"} allow="camera" />
      </div>
    </React.Fragment>
  );
}
