import React from 'react';
import { Formik, Form } from "formik";
import { kycStore } from "../KycContext/KycContext"
import { Row, Col } from "reactstrap";
import { globalStore } from '../../Global/GlobalContext/GlobalContext';
import MyButton from '../../Global/MyButton/MyButton'
import { registerPhone, registerEmail, createEmailConsents } from '../../../services/kyc.service';
import { catchBody } from '../../Global/Errors/ErrorAlert';
import * as yup from "yup";
import FormikInputGroup from '../../Global/FormikInputGroup/FormikInputGroup';
import FormikCheckboxGroup from '../../Global/FormikCheckboxGroup/FormikCheckboxGroup';

import "./PhoneAndEmailCollectionForm.scss"

const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const formValidationSchema = yup.object().shape({
  phoneNumber: yup
    .string()
    .matches(phoneRegExp, "Introduceți un număr de telefon valid.")
    .min(9, "Minim 9 caractere.")
    .max(13, "Maxim 13 caractere.")
    .required("Câmpul este obligatoriu."),
  email: yup
    .string()
    .email("Introduceți o adresă de email validă.")
    .required("Câmpul este obligatoriu."),
  agreement: yup.boolean().oneOf([true], "Selecția este obligatorie."),
  creditBureau: yup.boolean().oneOf([true], "Selecția este obligatorie."),
});

export default function PhoneAndEmailCollectionForm() {
  const { kycState, setKycState, advanceKyc, setKycFieldState, appInsightsTrackEvent } = React.useContext(kycStore);
  const { globalState, isMobile } = React.useContext(globalStore);

  return (
    <Formik
      initialValues={{
        phoneNumber: kycState.phoneNumber,
        email: kycState.email,
        termsAndConditions: kycState.termsAndConditions,
        agreement: false,
        creditBureau: false
      }}
      validationSchema={formValidationSchema}
      onSubmit={async (values) => {

        appInsightsTrackEvent({
          name: "PhoneAndEmailCollectionForm registerPhone",
        });

        registerPhone(kycState.clientSessionId, kycState.contactInfoSessionId, kycState.phoneNumber)
          .then((response) => {

            appInsightsTrackEvent({
              name: "PhoneAndEmailCollectionForm registerPhone - success",
              properties: {
                response: response,
              }
            });

            setKycState({
              phoneRegistrationId: response.data.registrationId,
            });

            appInsightsTrackEvent({
              name: "PhoneAndEmailCollectionForm registerPhone - registerEmail",
              properties: {
                response: response,
              }
            });

            registerEmail(kycState.clientSessionId, kycState.contactInfoSessionId, kycState.email)
              .then((response) => {

                appInsightsTrackEvent({
                  name: "PhoneAndEmailCollectionForm registerPhone -> registerEmail - success",
                  properties: {
                    response: response,
                  }
                });

                setKycState({
                  emailRegistrationId: response.data.registrationId,
                });

                appInsightsTrackEvent({
                  name: "PhoneAndEmailCollectionForm create email consents",
                });

                let consents = new Array<any>(
                  { type: "Marketing", isConsentGiven: kycState.termsAndConditions },
                  { type: "Gdpr", isConsentGiven: kycState.agreement },
                  { type: "CreditBureau", isConsentGiven: kycState.creditBureau }
                );

                let emailConsentsRequest = {
                  emailAddress: kycState.email,
                  consents: consents,
                  consentProvider: "Anonymous",
                  consentProviderDetails: "Kyc Independent",
                  nuid: undefined,
                  nuidCountryCode: undefined,
                  ip: undefined,
                  referenceApp: "Kyc Independent",
                  referenceName: undefined,
                  referenceId: undefined
                };

                createEmailConsents(emailConsentsRequest)
                  .then(() => {
                    advanceKyc();
                  })
                  .catch((error) => {
                    appInsightsTrackEvent({
                      name: "PhoneAndEmailCollectionForm create email consents - error",
                      properties: {
                        error: error,
                      }
                    });
                    catchBody(error);
                  })
                  .finally(() => {
                  });

              })
              .catch((error) => {
                appInsightsTrackEvent({
                  name: "PhoneAndEmailCollectionForm registerPhone -> registerEmail - error",
                  properties: {
                    error: error,
                  }
                });
                catchBody(error);
              })
              .finally(() => {
              });

          })
          .catch((error) => {
            appInsightsTrackEvent({
              name: "PhoneAndEmailCollectionForm registerPhone - error",
              properties: {
                error: error,
              }
            });
            catchBody(error);
          })
          .finally(() => {
          });

      }}
    >

      <React.Fragment >
        <div className="kyc-form-white">
          <label className="form-title">
            Hai să ne cunoaștem
          </label>
          <Form className="form"
            onChange={(e: any) => { setKycFieldState(e.target.name, e.target.value); }}
          >
            <FormikInputGroup hideLabel={kycState.phoneNumber ? false : true} isMobile={isMobile} text="Număr telefon" type="tel" name="phoneNumber" placeholder="Număr telefon"></FormikInputGroup>
            <FormikInputGroup hideLabel={kycState.email ? false : true} isMobile={isMobile} text="Adresă email" type="email" name="email" placeholder="Adresă email"></FormikInputGroup>

            <FormikCheckboxGroup className="mt-5" name="termsAndConditions" checked={kycState.termsAndConditions}>
              Sunt de acord cu{" "}
              <a href="https://www.omro.ro/acord-marketing">prelucrarea datelor în scop de marketing</a>
            </FormikCheckboxGroup>

            <FormikCheckboxGroup className="mt-2" name="agreement" checked={kycState.agreement}>
              Am citit și sunt de acord cu{" "}
              <a href="https://www.omro.ro/politica-confidentialitate">
                Politica privind prelucrarea datelor cu caracter personal
              </a>
            </FormikCheckboxGroup>

            <FormikCheckboxGroup className="mt-2" name="creditBureau" checked={kycState.creditBureau}>
              Declar că am luat la cunoștință și sunt de acord că OMRO IFN SA, instituție care deține marca înregistrată Filbo, prelucrează datele la S.C. Biroul de Credit S.A., conform termenilor și condițiilor detaliate în pagina{" "}
              <a href="https://www.omro.ro/participare-birou-de-credit">
                Participare OMRO IFN SA la Sistemul Biroului de Credit
              </a>
            </FormikCheckboxGroup>

            <Row>
              {/* <Col className="d-flex justify-content-end mb-4"> */}
              <Col md={{ size: 5, offset: 7 }} className="d-flex justify-content-end mt-4 mb-2">
                <MyButton
                  type="submit"
                  small
                  variant="primary"
                  className="mb-1"
                  loading={globalState.loading}
                  disabled={!kycState.contactInfoSessionId}
                >
                  Continuă
                </MyButton>
              </Col>
              {/* </Col> */}
            </Row>

          </Form>
        </div>
      </React.Fragment>

    </Formik >
  );
}
