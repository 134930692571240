import React from 'react';
import { Formik, Form } from "formik";
import { kycStore } from "../KycContext/KycContext"
import { Row, Col } from "reactstrap";
import { globalStore } from '../../Global/GlobalContext/GlobalContext';
import MyButton from '../../Global/MyButton/MyButton'
import { validatePhone } from '../../../services/kyc.service';
import { catchBody } from '../../Global/Errors/ErrorAlert';
import * as yup from "yup";
import "./PhoneNumberVerificationForm.scss";
import FormikInputGroup from '../../Global/FormikInputGroup/FormikInputGroup';


const formValidationSchema = yup.object().shape({
  phoneNumberVerificationCode: yup
    .number()
    .transform(value => (isNaN(value) ? 0 : value))
    .min(1, "Introduceți un cod valid (numeric,  4 caractere)")
    .max(9999, "Introduceți un cod valid (numeric, 4 caractere)")
    .required("Câmpul este obligatoriu."),
});

export default function PhoneNumberVerificationForm() {
  const { kycState, advanceKyc, setKycFieldState, appInsightsTrackEvent } = React.useContext(kycStore);
  const { globalState, isMobile } = React.useContext(globalStore);

  return (
    <Formik
      initialValues={{
        phoneNumberVerificationCode: kycState.phoneNumberVerificationCode,
      }}
      validationSchema={formValidationSchema}
      onSubmit={async (values) => {
        appInsightsTrackEvent({
          name: "PhoneNumberVerificationForm validatePhone",
        });
        validatePhone(kycState.clientSessionId, kycState.contactInfoSessionId, kycState.phoneRegistrationId, kycState.phoneNumberVerificationCode)
          .then((response) => {
            if (!response.data.success) {
              appInsightsTrackEvent({
                name: "PhoneNumberVerificationForm validatePhone - error - invalid code",
              });
              alert("Codul de verificare nu este corect.");
              return;
            }

            appInsightsTrackEvent({
              name: "PhoneNumberVerificationForm validatePhone - success",
              properties: {
                response: response,
              }
            });

            advanceKyc();

          })
          .catch((error) => {
            appInsightsTrackEvent({
              name: "PhoneNumberVerificationForm validatePhone - error",
              properties: {
                error: error,
              }
            });
            catchBody(error);
          }).finally(() => {

          });
      }}
    >
      <React.Fragment>
        <div className="kyc-form-white">
          <label className="form-title">
            Te rugăm să introduci codul primit prin SMS
      </label>
          <Form
            onChange={(e: any) => setKycFieldState(e.target.name, e.target.value)}
          >
            <FormikInputGroup hideLabel={kycState.phoneNumberVerificationCode ? false : true} isMobile={isMobile} text="Cod verificare SMS" type="tel" name="phoneNumberVerificationCode" placeholder="Cod verificare SMS"></FormikInputGroup>
            <Row>
              <Col className="d-flex justify-content-end mb-4">
                <Col md={{ size: 5, offset: 7 }} className="d-flex justify-content-end mt-4 mb-2">
                  <MyButton
                    type="submit"
                    small
                    variant="primary"
                    className="mb-1"
                    loading={globalState.loading}
                    disabled={!kycState.contactInfoSessionId}
                  >
                    Verifică
                  </MyButton>
                </Col>
              </Col>
            </Row>
          </Form>
        </div>
      </React.Fragment>
    </Formik >

  );
}
