export enum KycStep {
    PhoneNumber = "PhoneNumber",
    Email = "Email",
    ExternalKycProvider = "ExternalKycProvider",
    PhoneAndEmail = "PhoneAndEmail",
    ThankYou = "ThankYou",
    FilboMobileRedirectHelper = "FilboMobileRedirectHelper",
}

export enum KycForm {
    PhoneNumberCollection = "PhoneNumberCollection",
    PhoneNumberVerification = "PhoneNumberVerification",
    EmailCollection = "EmailCollection",
    EmailVerification = "EmailVerification",
    ExternalKycProviderConsent = "ExternalKycProviderConsent",
    ExternalKycProviderSelfie = "ExternalKycProviderSelfie",
    ExternalKycProviderLiveness = "ExternalKycProviderLiveness",
    ExternalKycProviderIdScan = "ExternalKycProviderIdScan",
    ThankYou = "ThankYou",
    PhoneAndEmailCollection = "PhoneAndEmailCollection",
    FilboMobileRedirectHelper = "FilboMobileRedirectHelper",
}

export const kycAvailableSteps = [
    {
        kycStep: KycStep.PhoneNumber,
        formsConfiguration: [KycForm.PhoneNumberCollection, KycForm.PhoneNumberVerification],
    },
    {
        kycStep: KycStep.Email,
        formsConfiguration: [KycForm.EmailCollection, KycForm.EmailVerification],
    },
    {
        kycStep: KycStep.PhoneAndEmail,
        formsConfiguration: [KycForm.PhoneAndEmailCollection, KycForm.PhoneNumberVerification, KycForm.EmailVerification],
    },
    {
        kycStep: KycStep.ExternalKycProvider,
        formsConfiguration: [KycForm.ExternalKycProviderConsent, KycForm.ExternalKycProviderSelfie, KycForm.ExternalKycProviderLiveness, KycForm.ExternalKycProviderIdScan],
    },
    {
        kycStep: KycStep.ThankYou,
        formsConfiguration: [KycForm.ThankYou],
    },
    {
        kycStep: KycStep.FilboMobileRedirectHelper,
        formsConfiguration: [KycForm.FilboMobileRedirectHelper],
    },
];
