import React from 'react';
import { Formik, Form, Field, ErrorMessage } from "formik";
import { kycStore } from "../KycContext/KycContext"
import { Row, Col } from "reactstrap";
import { globalStore } from '../../Global/GlobalContext/GlobalContext';
import MyButton from '../../Global/MyButton/MyButton'
import { registerPhone } from '../../../services/kyc.service';
import { catchBody } from '../../Global/Errors/ErrorAlert';
import * as yup from "yup";
import "./PhoneNumberCollectionForm.scss"
const phoneRegExp = /^((\\+[1-9]{1,4}[ \\-]*)|(\\([0-9]{2,3}\\)[ \\-]*)|([0-9]{2,4})[ \\-]*)*?[0-9]{3,4}?[ \\-]*[0-9]{3,4}?$/;

const formValidationSchema = yup.object().shape({
  phoneNumber: yup
    .string()
    .matches(phoneRegExp, "Introduceți un număr de telefon valid.")
    .min(9, "Minim 9 caractere.")
    .max(13, "Maxim 13 caractere.")
    .required("Câmpul este obligatoriu."),
});

export default function PhoneNumberCollectionForm() {
  const { kycState, setKycState, advanceKyc, setKycFieldState, appInsightsTrackEvent } = React.useContext(kycStore);
  const { globalState } = React.useContext(globalStore);

  const createInputGroup = (name: string, label: string, type: string) => {
    return (
      <Col>
        <label className="formLabel">{label}</label>
        <div className="input-group">
          <Field type={type} name={name} className="inputField form-control" />
        </div>
        <ErrorMessage name={name} className="error" component="div" />
      </Col>
    );
  };

  return (
    <Formik
      initialValues={{
        phoneNumber: kycState.phoneNumber,
      }}
      validationSchema={formValidationSchema}
      onSubmit={async (values) => {

        appInsightsTrackEvent({
          name: "PhoneNumberCollectionForm registerPhone",
        });

        registerPhone(kycState.clientSessionId, kycState.contactInfoSessionId, kycState.phoneNumber)
          .then((response) => {

            appInsightsTrackEvent({
              name: "PhoneNumberCollectionForm registerPhone - success",
              properties: {
                response: response,
              }
            });

            setKycState({
              phoneRegistrationId: response.data.registrationId,
            });

            advanceKyc();
          })
          .catch((error) => {
            appInsightsTrackEvent({
              name: "PhoneNumberCollectionForm registerPhone - error",
              properties: {
                error: error,
              }
            });
            catchBody(error);
          })
          .finally(() => {
          });

      }}
    >

      <React.Fragment >
        <header>
          <p>
            Phone collection form header
            </p>
        </header>
        <Form
          onChange={(e: any) => { setKycFieldState(e.target.name, e.target.value); }}
        >
          <Row className="mt-2">
            {createInputGroup("phoneNumber", "Număr telefon", "tel")}
          </Row>
          <Row>
            <Col className="d-flex justify-content-end mb-4">
              <Col md={{ size: 5, offset: 7 }} className="d-flex justify-content-end mt-4 mb-2">
                <MyButton
                  type="submit"
                  small
                  variant="primary"
                  className="mb-1"
                  loading={globalState.loading}
                  disabled={!kycState.contactInfoSessionId}
                >
                  Continuă
                  </MyButton>
              </Col>
            </Col>
          </Row>

        </Form>
      </React.Fragment>
    </Formik>
  );
}
