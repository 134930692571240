import React, { useEffect } from 'react';
import { kycStore } from "../KycContext/KycContext"
import { kycAvailableSteps, KycForm, KycStep } from "../KycContext/kycAvailableSteps";
import PhoneNumberCollectionForm from '../PhoneNumberCollectionForm/PhoneNumberCollectionForm'
import PhoneNumberVerificationForm from '../PhoneNumberVerificationForm/PhoneNumberVerificationForm'
import EmailCollectionForm from '../EmailCollectionForm/EmailCollectionForm'
import PhoneAndEmailCollectionForm from '../PhoneAndEmailCollectionForm/PhoneAndEmailCollectionForm'
import EmailVerificationForm from '../EmailVerificationForm/EmailVerificationForm'
import ExternalKycProviderIFrameForm from '../ExternalKycProviderIFrameForm/ExternalKycProviderIFrameForm'
import ExternalKycProviderConsentForm from '../ExternalKycProviderConsentForm/ExternalKycProviderConsentForm';
import ProgressBarLine from '../ProgressBarLine/ProgressBarLine';
import { Container } from 'reactstrap';
import { Helmet } from 'react-helmet';
import Header from '../../Global/Header/Header'
import qs from 'qs';
import './KycHome.scss';
import ThankYou from '../ThankYou/ThankYou';//this must come after import './KycHome.scss';


export default function KycHome(props: any) {
  const { kycState, startNewKycClientSession, continueKycClientSession, appInsightsTrackEvent } = React.useContext(kycStore);

  const { history } = props;

  useEffect(() => {
    const query = qs.parse(window.location.search, {
      ignoreQueryPrefix: true
    })

    if (query.contactInfoClientSessionId && query.contactInfoSessionId) {
      attemptToContinueKycSession(query);
    }
    else {
      startNewKycClientSession();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [startNewKycClientSession]);

  const getCurrentForm = () => {
    switch (kycState.currentForm) {
      case KycForm.PhoneNumberCollection:
        return <PhoneNumberCollectionForm />;
      case KycForm.PhoneNumberVerification:
        return <PhoneNumberVerificationForm />;
      case KycForm.EmailCollection:
        return <EmailCollectionForm />;
      case KycForm.EmailVerification:
        return <EmailVerificationForm />;
      case KycForm.ExternalKycProviderConsent:
        return <ExternalKycProviderConsentForm />;
      case KycForm.ExternalKycProviderSelfie:
      case KycForm.ExternalKycProviderLiveness:
      case KycForm.ExternalKycProviderIdScan:
        return <ExternalKycProviderIFrameForm />;
      case KycForm.ThankYou:
        return <ThankYou />;
      case KycForm.PhoneAndEmailCollection:
        return <PhoneAndEmailCollectionForm />;
    }
  };

  return (
    <React.Fragment>
      <Helmet>
        <title>Filbo Identity</title>
        <meta name="description" content="Identitatatea ta digitală" />
      </Helmet>
      <Header>Verificare cu selfie și carte de identitate</Header>

      <Container className="kyc-container">
        <ProgressBarLine className="kyc-prog-bar" percent={kycState.percent}></ProgressBarLine>
        {getCurrentForm()}
      </Container>

    </React.Fragment >
  );

  function attemptToContinueKycSession(query: qs.ParsedQs) {
    console.log(new Date().toISOString() + " query " + JSON.stringify(query));

    appInsightsTrackEvent({
      name: "attemptToContinueKycSession starting",
      properties: {
        query: query,
      }
    });

    if (query.steps) {
      var stepsString: string = query.steps as string;

      var kycSteps = [];
      var splitted = stepsString.split(" ");
      for (let index = 0; index < splitted.length; index++) {
        const kycStep = kycAvailableSteps.filter((s: any) => s.kycStep.toUpperCase() === splitted[index].toUpperCase())[0];

        if (kycStep) {
          kycSteps[index] = kycStep.kycStep;
        }
        else {
          console.log(new Date().toISOString() + ` step from query not recognized '${splitted[index]}'`);
        }

      }

      if (kycSteps.length > 0) {
        console.log(new Date().toISOString() + " found steps in url: " + JSON.stringify(kycSteps));

        appInsightsTrackEvent({
          name: "attemptToContinueKycSession found steps in url",
          properties: {
            kycSteps: kycSteps,
          }
        });

        kycSteps[kycSteps.length] = KycStep.ThankYou;
      }
    }

    continueKycClientSession(query.contactInfoClientSessionId, query.contactInfoSessionId, kycSteps);

    history.replace();
  }
}