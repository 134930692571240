import React from "react";
import "./MyButton.scss";
import classNames from "classnames";

interface Props {
  children?: React.ReactNode;
  block?: boolean;
  className?: string;
  small: boolean;
  active: boolean;
  onClick?: (event: React.MouseEvent) => void;
  variant?: "primary" | "grey" | "white";
  type?: any;
  disabled?: boolean;
  loading?: boolean;
  style?: any;
}

export default function MyButton({
  children,
  block,
  className,
  onClick,
  active,
  small,
  variant,
  type,
  disabled,
  loading,
  style,
  ...props
}: Props) {
  const buttonStyle = classNames(
    "MyButton",
    className,
    {
      "display-block": block,
    },
    {
      "disabledMyButton": disabled,
    },
    {
      small: small,
    },
    {
      "mybutton-active": active,
    },
    {
      [`${variant}`]: variant,
    }
  );

  return (
    <button onClick={onClick} type={type} className={buttonStyle} style={style} disabled={disabled} {...props}>
      {loading ? (
        <React.Fragment>
          <span className="spinner-border button-spinner" role="status" aria-hidden="true"></span>
          <span className="sr-only">Loading...</span>
        </React.Fragment>
      ) : (
          children
        )}
    </button>
  );
}

MyButton.defaultProps = {
  block: false,
  small: false,
  active: false,
  variant: "primary",
};
