import React from "react";
import "./FormikCheckboxGroup.scss";
import classNames from "classnames";
import { Field, ErrorMessage } from "formik";
import Text from '../../Global/Text/Text'
import { Col, Row } from "reactstrap";

interface Props {
  children?: React.ReactNode;
  name: string;
  checked: boolean;
  className?: string;
}

export default function FormikCheckboxGroup({
  children,
  name,
  checked,
  className,
}: Props) {
  const inputClasses = classNames(
    className,
    "firstRow"
  );
  return (
    <React.Fragment>
      <Row className={inputClasses}>
        <Col md={1} xs={2}>
          <Field className="checkBoxField" type="checkbox" name={name} checked={checked}  />
        </Col>
        <Col xs={10} className="d-flex align-items-center">
          <Text size={12}>
            {children}
          </Text>
        </Col>
      </Row>
      <Row>
        <Col md={1} xs={2}>

        </Col>
        <Col xs={10} className="d-flex align-items-center">
          <ErrorMessage name={name} className="error" component="div" />
        </Col>
      </Row>
    </React.Fragment>
  );
}
